import '../networkDetector.platform';

import isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import * as jsonpatch from 'readwise-fast-json-patch';
import create, { GetState, PartialState, SetState, State, StoreApi } from 'zustand';
import { devtools, StoreApiWithSubscribeWithSelector, subscribeWithSelector } from 'zustand/middleware';

import { AST } from '../filters-compiler/types';
import { NetworkStatus } from '../NetworkDetector';
import type {
  ClientState,
  FocusedHighlightIdState,
  FullZustandState,
  PersistentState,
  SettingsState,
  SplitByValue,
  UserEvent,
  UserEventsState,
  UserEventWithDataUpdate,
} from '../types';
import {
  BulkActionType,
  Category,
  DisplayTheme,
  DocumentLocation,
  FeedDocumentLocation,
  HeadphoneAction,
  MainTitleType,
  PersistentStateLoadingState,
  SortKey,
  SortOrder,
  SortRule,
  SplitByKey,
  SubMenu,
  TableSortKey,
  TextDirection,
  TshirtSize,
} from '../types';
import {
  ClassicThemeVariantId,
  FreshVariantId,
  GradientVariantId,
  ModernVariantId,
  QuoteshotAspectRatio,
  QuoteshotFont,
  QuoteshotThemeName,
  ScribbleVariantId,
  UnstyledVariantId,
} from '../types/quoteshots';
import { isBoox, isDevOrTest, isExtension, isZustandDevToolsEnabled } from '../utils/environment';
import { generateRandomToken } from '../utils/generateRandomToken';
import produce from '../utils/immer';
import makeLogger from '../utils/makeLogger';
import safeJsonPatchCompareOfState from '../utils/safeJsonPatchCompareOfState';
import { MOBILE_DEFAULT_FONT, WEB_DEFAULT_FONT } from './fonts';
import handleStatePathsThatNeedToBeUpdatedAsAWhole from './handleStatePathsThatNeedToBeUpdatedAsAWhole';
// eslint-disable-next-line import/no-cycle
import handleStateUpdateSideEffects from './handleStateUpdateSideEffects';
// eslint-disable-next-line import/no-cycle
import background from './portalGates/toBackground';
import getDefaultSwipeConfig from './utils/getDefaultSwipeConfig';
// eslint-disable-next-line import/no-cycle

const logger = makeLogger(__filename);

// zustand doesn't export this
type NamedSet<T extends State> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  <K extends keyof T>(partial: PartialState<T, K>, replace?: boolean, name?: string): void;
};

type DocumentListProperties = {
  categoryToFilterAndSortWith?: Category;
  filterByDocumentLocation?: DocumentLocation;
  filterByFeedDocumentLocation?: FeedDocumentLocation;
  filter?: { ast: AST; query: string };
  splitByKey?: SplitByKey;
  splitByValue?: string;
};
export const getListId = ({
  categoryToFilterAndSortWith,
  filterByDocumentLocation,
  filterByFeedDocumentLocation,
  filter,
  splitByKey,
  splitByValue,
}: DocumentListProperties) => {
  // Warning: don't mess with the string structure as the getListPropertiesFromId function depends on it
  return `category:${categoryToFilterAndSortWith}-documentLocation:${filterByDocumentLocation}-feedDocumentLocation:${filterByFeedDocumentLocation}-filter:${filter?.query}-splitByKey:${splitByKey}-splitByValue:${splitByValue}`;
};

export enum FilteredViewScreen {
  SplitByNothing = 'splitByNothing',

  // The order matters here:
  SplitByDocumentLocationNew = 'splitByDocumentLocationNew',
  SplitByDocumentLocationLater = 'splitByDocumentLocationLater',
  SplitByDocumentLocationShortlist = 'splitByDocumentLocationShortlist',
  SplitByDocumentLocationArchive = 'splitByDocumentLocationArchive',

  SplitBySeenStatusUnseen = 'splitBySeenStatusUnseen',
  SplitBySeenStatusSeen = 'splitBySeenStatusSeen',
}

export const splitBySeenScreens = Object.values(FilteredViewScreen).filter((value) =>
  value.startsWith('splitBySeen'),
);

export const splitByDocumentLocationScreens = Object.values(FilteredViewScreen).filter((value) =>
  value.startsWith('splitByDocumentLocation'),
);

export const SplitByScreenSplitByValue: { [key: string]: string | undefined } = {
  [FilteredViewScreen.SplitByNothing]: undefined,

  [FilteredViewScreen.SplitByDocumentLocationArchive]: DocumentLocation.Archive,
  [FilteredViewScreen.SplitByDocumentLocationLater]: DocumentLocation.Later,
  [FilteredViewScreen.SplitByDocumentLocationNew]: DocumentLocation.New,
  [FilteredViewScreen.SplitByDocumentLocationShortlist]: DocumentLocation.Shortlist,

  [FilteredViewScreen.SplitBySeenStatusUnseen]: FeedDocumentLocation.New,
  [FilteredViewScreen.SplitBySeenStatusSeen]: FeedDocumentLocation.Seen,
};

export enum ForegroundEventName {
  DownloadPdf = 'DownloadPdf',
  ShowPdfThumbnails = 'ShowPdfThumbnails',
  GoToPdfPage = 'GoToPdfPage',
  GoToNextPdfPage = 'GoToNextPdfPage',
  GoToPrevPdfPage = 'GoToPrevPdfPage',
  OnDragStart = 'OnDragStart',
  OnDragEnd = 'OnDragEnd',
  DocumentListContainerSetParams = 'DocumentListContainerSetParams',
}

export const SortKeyDisplayName = {
  [SortKey.LastStatusUpdate]: 'Date moved',
  [SortKey.SavedAt]: 'Date saved',
  [SortKey.ReadingProgress]: 'Progress',
  [SortKey.Title]: 'Title',
  [SortKey.Author]: 'Author',
  [SortKey.Category]: 'Category',
  [SortKey.LastOpenedAt]: 'Date last opened',
  [SortKey.WordCount]: 'Length',
  [SortKey.Published]: 'Date published',
  [SortKey.Random]: 'Random',
};

const SortOrderDisplayNameByType = {
  date: {
    [SortOrder.Asc]: 'Old → Recent',
    [SortOrder.Desc]: 'Recent → Old',
  },
  string: {
    [SortOrder.Asc]: 'A → Z',
    [SortOrder.Desc]: 'Z → A',
  },
  integer: {
    [SortOrder.Asc]: '0 → 100',
    [SortOrder.Desc]: '100 → 0',
  },
};

export const SortOrderDisplayName = {
  [SortKey.LastStatusUpdate]: SortOrderDisplayNameByType.date,
  [SortKey.SavedAt]: SortOrderDisplayNameByType.date,
  [SortKey.LastOpenedAt]: SortOrderDisplayNameByType.date,
  [SortKey.Published]: SortOrderDisplayNameByType.date,

  [SortKey.Title]: SortOrderDisplayNameByType.string,
  [SortKey.Author]: SortOrderDisplayNameByType.string,
  [SortKey.Category]: SortOrderDisplayNameByType.string,

  [SortKey.ReadingProgress]: SortOrderDisplayNameByType.integer,
  [SortKey.WordCount]: SortOrderDisplayNameByType.integer,
};

// These are the keys that are used in the mobile sort views menu
export const TableSortKeyDisplayName = {
  [TableSortKey.Name]: 'Name',
  [TableSortKey.Documents]: 'Document count',
  [TableSortKey.LastUpdated]: 'Last updated',
  [TableSortKey.Manual]: 'Manual order (from web)',
};

export const TableSortOrderDisplayName = {
  [TableSortKey.Name]: SortOrderDisplayNameByType.string,
  [TableSortKey.Documents]: SortOrderDisplayNameByType.integer,
  [TableSortKey.LastUpdated]: SortOrderDisplayNameByType.date,
};

export const DEFAULT_DOCUMENT_SORT_RULES = {
  [DocumentLocation.New]: [
    {
      id: '1',
      key: SortKey.LastStatusUpdate,
      order: SortOrder.Desc,
    },
  ],
  [DocumentLocation.Later]: [
    {
      id: '2',
      key: SortKey.LastStatusUpdate,
      order: SortOrder.Desc,
    },
  ],
  [DocumentLocation.Archive]: [
    {
      id: '3',
      key: SortKey.LastStatusUpdate,
      order: SortOrder.Desc,
    },
  ],
  filterView: [
    {
      id: '5',
      key: SortKey.SavedAt,
      order: SortOrder.Desc,
    },
  ],
} as const;

export const DEFAULT_SORT_RULE_ID = '1';

export const DEFAULT_SORT_RULES = [
  {
    id: DEFAULT_SORT_RULE_ID,
    key: SortKey.LastStatusUpdate,
    order: SortOrder.Desc,
  },
];

const defaultDocumentLocationListSortRules: { [listId: string]: SortRule[] } = Object.fromEntries(
  Object.values(DocumentLocation).map((documentLocation, index) => {
    return [
      getListId({ filterByDocumentLocation: documentLocation }),
      [
        {
          id: (index + 1).toString(),
          key: SortKey.LastStatusUpdate,
          order: SortOrder.Desc,
        },
      ],
    ];
  }),
);

export const DEFAULT_DOCUMENT_LIST_SORT_RULES_STATE: { [listId: string]: SortRule[] } = {
  ...defaultDocumentLocationListSortRules,
  [getListId({
    filterByDocumentLocation: DocumentLocation.Feed,
    filterByFeedDocumentLocation: FeedDocumentLocation.Seen,
  })]: [
    {
      id: '6',
      key: SortKey.SavedAt,
      order: SortOrder.Desc,
    },
  ],
  [getListId({
    filterByDocumentLocation: DocumentLocation.Feed,
    filterByFeedDocumentLocation: FeedDocumentLocation.New,
  })]: [
    {
      id: '7',
      key: SortKey.SavedAt,
      order: SortOrder.Desc,
    },
  ],
};

export const SplitByKeyDisplayName = {
  [SplitByKey.DocumentLocation]: 'Location',
  [SplitByKey.Seen]: 'Seen',
};

export const SplitBySeenValues = {
  unseen: {
    name: 'unseen',
    queryParamValue: 'false',
  },
  seen: {
    name: 'seen',
    queryParamValue: 'true',
  },
};

export const SplitByValues = {
  [SplitByKey.DocumentLocation]: Object.values(DocumentLocation).reduce(
    (acc: { [key: string]: SplitByValue }, documentLocation) => {
      if (documentLocation === DocumentLocation.Feed || documentLocation === DocumentLocation.Deleted) {
        return acc;
      }

      return {
        ...acc,
        [documentLocation]: {
          name: documentLocation,
          queryParamValue: documentLocation,
        },
      };
    },
    {},
  ),

  [SplitByKey.Seen]: SplitBySeenValues,
};

export const groupTitleByBulkType = {
  [BulkActionType.AllDocs]: 'All Documents in List',
  [BulkActionType.AboveFocusedDoc]: 'Above Focused Document',
  [BulkActionType.BelowFocusedDoc]: 'Below Focused Document',
};

export const mainTitleTypeByBulkType = {
  [BulkActionType.AllDocs]: MainTitleType.AllDocumentsInList,
  [BulkActionType.AboveFocusedDoc]: MainTitleType.AboveFocusedDoc,
  [BulkActionType.BelowFocusedDoc]: MainTitleType.BelowFocusedDoc,
};

export const subMenuTypeByBulkType = {
  [BulkActionType.AllDocs]: SubMenu.BulkActionsTagsAll as SubMenu.BulkActionsTagsAll,
  [BulkActionType.AboveFocusedDoc]: SubMenu.BulkActionsTagsAbove as SubMenu.BulkActionsTagsAbove,
  [BulkActionType.BelowFocusedDoc]: SubMenu.BulkActionsTagsBelow as SubMenu.BulkActionsTagsBelow,
};

export const bulkActionTypeBySubmenu = {
  [SubMenu.BulkActionsTagsAll]: BulkActionType.AllDocs,
  [SubMenu.BulkActionsTagsAbove]: BulkActionType.AboveFocusedDoc,
  [SubMenu.BulkActionsTagsBelow]: BulkActionType.BelowFocusedDoc,
};

export const aboveBelowOrAll = (bulkType: BulkActionType) => {
  if (bulkType === BulkActionType.AboveFocusedDoc) {
    return 'above';
  }

  if (bulkType === BulkActionType.BelowFocusedDoc) {
    return 'below';
  }

  return 'all';
};

type GetInitialFocusedHighlightIdStateFunction = (
  set: NamedSet<FocusedHighlightIdState>,
  get: GetState<FocusedHighlightIdState>,
  api: StoreApi<FocusedHighlightIdState>,
) => FocusedHighlightIdState;

const getInitialFocusedHighlightIdState = (
  ...zustandArguments: Parameters<GetInitialFocusedHighlightIdStateFunction>
): ReturnType<GetInitialFocusedHighlightIdStateFunction> => {
  const [set, get] = zustandArguments;
  const update: FocusedHighlightIdState['update'] = async (updater) => {
    const lastState = get();
    // Produce the nextState of zustand using immer's produce function:
    const nextState = produce(lastState, (draft) => {
      updater(draft);
    });
    set(() => {
      return nextState;
    }, undefined);
  };
  return {
    update,
    focusedHighlightId: null,
  };
};

export const focusedHighlightIdState = create<
  FocusedHighlightIdState,
  SetState<FocusedHighlightIdState>,
  GetState<FocusedHighlightIdState>,
  StoreApiWithSubscribeWithSelector<FocusedHighlightIdState>
>(subscribeWithSelector((...args) => getInitialFocusedHighlightIdState(...args)));

export const userEventsState = create<UserEventsState>(() => ({
  userEvents: [],
}));

export async function addUserEvent(userEvent: UserEvent): Promise<void> {
  userEventsState.setState((state) => ({
    userEvents: [...state.userEvents, userEvent],
  }));
}

export const getDefaultClientState = ({
  documentLocations,
}: {
  documentLocations: SettingsState['documentLocations'];
}): ClientState => ({
  theme: DisplayTheme.System,
  recentSearches: [],
  profile: null,
  dailyDigestHomeNudgeDisabled: false,
  rightSidebarHiddenInList: false,
  hideRightPanelOnEnteringReadingView: false,
  hideLeftPanelOnEnteringReadingView: false,
  readerSettings: {
    desktop: {
      fontSize: TshirtSize.M,
      lineHeight: TshirtSize['2XS'],
      lineLength: TshirtSize.M,
      font: WEB_DEFAULT_FONT,
      direction: TextDirection.LeftToRight,
      justifyText: false,
    },
    mobile: {
      fontSize: TshirtSize.XS,
      lineHeight: TshirtSize['2XS'],
      pageWidth: TshirtSize.XL,
      font: MOBILE_DEFAULT_FONT,
      direction: TextDirection.LeftToRight,
      paginationOnByDefaultList: [Category.EPUB],
      arePaginationAnimationsDisabled: false,
      arePaginationHapticsOnScrollEnabled: false,
      justifyText: false,
    },
  },
  showDailyReview: null,
  mobileDeveloperSettings: {
    showReadingViewLoadingTime: false,
    showPdfFileFetchedTime: false,
    showInspectorAlertInWebview: false,
  },
  quoteshot: {
    currentThemeName: QuoteshotThemeName.Classic,
    currentFont: QuoteshotFont.SansSerif,
    aspectRatio: QuoteshotAspectRatio.Square,
    isDarkMode: false,
    variantForTheme: {
      [QuoteshotThemeName.Classic]: ClassicThemeVariantId.Yellow,
      [QuoteshotThemeName.Fresh]: FreshVariantId.BlueRed,
      [QuoteshotThemeName.Modern]: ModernVariantId.OrangePink,
      [QuoteshotThemeName.Scribble]: ScribbleVariantId.Brush,
      [QuoteshotThemeName.Gradient]: GradientVariantId.Pink,
      [QuoteshotThemeName.Unstyled]: UnstyledVariantId.White,
    },
  },
  listSortRules: DEFAULT_DOCUMENT_LIST_SORT_RULES_STATE,
  largeFeedView: false,
  documents: {},
  askToPasteUrls: true,
  isHighContrastMode: null,
  isGhostreaderEnabled: true,
  keepAwakeWhileReading: true,
  mobileHapticsEnabled: true,
  mobileTapToShowMenusDisabled: false,
  openLinksInApp: true,
  swipeConfig: getDefaultSwipeConfig(documentLocations),
  autoAdvance: false,
  youtube: {
    autoScroll: true,
    playbackRate: 1,
  },
  mobileLastAnnotationSubpanelOpened: undefined,
  mobileSafeAreaInsets: { top: 0, left: 0, right: 0, bottom: 0 },
  mobileHomeNavBarHeight: 56,
  sortViewsByKey: TableSortKey.Manual,
  sortViewsByOrder: SortOrder.Desc,
  sortFeedsByKey: TableSortKey.LastUpdated,
  sortFeedsByOrder: SortOrder.Desc,
  sortTagsByKey: TableSortKey.Name,
  sortTagsByOrder: SortOrder.Asc,
  headphoneGestures: {
    previousTrack: HeadphoneAction.JumpBackward,
    nextTrack: HeadphoneAction.JumpForward,
  },
  shouldInvertPDFColors: true,
  mobileShouldUseVolumeButtonsToScrollPages: isBoox,
  navigationSidebar: {
    isWebNavigationSidebarHidden: false,
    isLibraryCollapsed: false,
    isTagsCollapsed: false,
    isFeedCollapsed: true,
    isPinnedViewsCollapsed: false,
  },
  mobileAreAnimationsDisabled: null,
  trashRevalidationToken: generateRandomToken(),
});

export const getDefaultFullZustandState = (): Omit<FullZustandState, 'isStateMinimized' | 'update'> => {
  const documentLocations = [
    DocumentLocation.Feed,
    DocumentLocation.New,
    DocumentLocation.Later,
    DocumentLocation.Archive,
  ];

  return {
    areAllDatabaseHooksDisabled: false,
    canUndoAction: false,
    client: getDefaultClientState({ documentLocations }),
    clientStateLoaded: false,
    haveSomeDocumentContentItemsLoaded: false,
    highlightResizeState: {
      edgeResizeStartedFrom: null,
      highlightBeingResizedHasAlternativeColor: null,
      idOfHighlightBeingResized: null,
      idsOfHighlightsWithResizeHandlesShown: [],
      status: 'inactive',
    },
    persistentStateLoaded: false,
    areViewsDocumentCountsIndexing: false,
    areFeedsDocumentCountsIndexing: false,
    areServerUpdatesBeingAppliedToForeground: false,
    areServerUpdatesBeingFetchedByUser: false,
    openDocumentId: null,
    persistentStateLoadingState: PersistentStateLoadingState.HasNotStarted,
    persistentStateTotalDocumentsToAddCount: '0',
    persistentStateNumberOfDocumentsAdded: 0,
    cmdPalette: {
      isOpen: false,
      subMenu: SubMenu.Normal,
      openSubmenus: [],
    },
    quoteshotModalOpen: false,
    quoteshotHighlightId: null,
    documentsListScrolled: false,
    documentSummaryController: null,
    emptyStateCategory: null,
    filterPreviousRoute: '/',
    findInDocumentState: null,
    focusedDocumentId: null,
    focusedDocumentListQuery: null,
    focusedFeedId: null,
    focusedTagId: null,
    focusedViewId: null,
    focusedHighlightId: null,
    gptPromptLoading: false,
    gptPrompt: null,
    highlightIdToOpenAt: null,
    isAppearancePanelShown: false,
    isMigratingSearchDatabase: false,
    isMobileCPUThrottled: false,
    isMobileAppReviewDebugging: false,
    isVideoHeaderShown: true,
    isVideoSettingsPanelShown: false,
    isDeleteDocumentDialogOpen: false,
    isDocMoreActionsDropdownOpen: false,
    isDocumentSummaryGenerating: false,
    isNotebookDropdownOpen: false,
    isEditTagsPopoverShown: false,
    isDocumentMetadataShown: false,
    isDocumentsSortMenuShown: false,
    isInboxZero: false,
    isOnline: true,
    keyboardShortcuts: {},
    leftSidebarHiddenForNarrowScreen: false,
    leftSidebarHiddenInReadingView: false,
    linkActionsUrl: null,
    mobileAppState: 'active',
    mobileActiveTableOfContentsHeadingId: undefined,
    mobileCurrentFocusedDocumentListId: undefined,
    mobileDocumentTableOfContents: [],
    mobileDocumentImageModalData: null,
    mobileImageModalDataLinkAction: null,
    mobileFocusedListState: {
      isFilteredView: false,
      isFeedView: false,
      savedFilterView: undefined,
      filterQuery: undefined,
      routeName: '',
    },
    // This refers to LoggedInRootContainer, not the home screen
    mobileIsEmptyListState: false,
    mobileLoggedIn: null,
    mobileActiveTab: null,
    mobileSelectedFilteredViewQuery: null,
    modal: null,
    networkStatus: NetworkStatus.Offline,
    openNotebookId: null,
    // Hack to make typescript happy -- it will only be this emptyish value before state is initialized:
    persistent: {
      settings: {
        defaultPage: 'library',
        documentLocations,
        web: {
          isAutoHighlightingEnabled: true,
        },
      },
    } as PersistentState,
    possibleRssFeeds: {},
    rightSidebarHiddenForNarrowScreen: false,
    rightSidebarHiddenInReadingView: false,
    routeStack: [],
    screenWidth: 0,
    tagNamesUsedRecently: [],
    temporarilyCachedFeedSuggestions: null,
    temporarilyCachedFeedSuggestionsSelection: null,
    transientDocumentsData: {},
    tts: null,
    ttsFetchingTimestamp: false,
    // If you need to use the theme in the web app *in JS*, use this. The regular theme value can contain `system`
    webEffectiveTheme: DisplayTheme.Dark,
    zenModeEnabled: false,
    youtube: {
      seekTo: null,
      isPlaying: false,
    },
    filterQueryToCreate: null,
    isPdfSnapToolEnabled: false,
    openedAnnotationBarPopoverHighlightId: null,
    pathNameToRedirectAfterOnboarding: null,
    filteredViewIdToDelete: null,
    feedIdToDelete: null,
    feedIdsToAddInFolder: [],
    // This will be set to true after 10 seconds of the app being open
    shouldRunSidebarItemCounts: false,
  };
};

type GetInitialStateFunction = (
  set: NamedSet<FullZustandState>,
  get: GetState<FullZustandState>,
  api: StoreApi<FullZustandState>,
) => FullZustandState;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addDevToolsMiddleware = (callback: GetInitialStateFunction): any => {
  if (isZustandDevToolsEnabled) {
    return devtools(callback) as GetInitialStateFunction;
  }
  return callback;
};

/**
 * Represents a pseudo-error that indicates the cancellation of a state update.
 * Most times it's thrown when state is determined to have not changed.
 * @extends Error
 */
export class CancelStateUpdate extends Error {}

const getInitialState = (
  { shouldMinimize }: { shouldMinimize?: boolean } = {},
  ...zustandArguments: Parameters<GetInitialStateFunction>
): ReturnType<GetInitialStateFunction> => {
  const [set] = zustandArguments;
  const update: FullZustandState['update'] = async (updater, options) => {
    let nextState: FullZustandState | undefined;
    let lastState: FullZustandState | undefined;
    // Perform the Zustand state update
    set(
      (draft) => {
        lastState = draft;
        try {
          nextState = produce(draft, updater);
        } catch (error) {
          if (error instanceof CancelStateUpdate) {
            nextState = undefined;
            return draft;
          }
          throw error;
        }

        if (isDevOrTest) {
          // Safety checks
          if (isEqual(draft, nextState)) {
            logger.warn('updateState: state unchanged in set()', {
              eventName: options.eventName,
            });
          }
        }

        return nextState;
      },
      undefined,
      options.eventName,
    );

    if (nextState === undefined) {
      // We got a signal to cancel update, so early return.
      return {};
    }
    if (!lastState) {
      throw new Error('lastState was not set inside of the set() call');
    }
    // Produce the nextState of zustand using immer's produce function:
    const willChangeAnyState = nextState !== lastState;

    let jsonPatchOperations:
      | {
          forward: jsonpatch.Operation[];
          reverse: jsonpatch.Operation[];
        }
      | undefined;
    if (willChangeAnyState) {
      const forwardJsonPatchOperations = safeJsonPatchCompareOfState(
        lastState.persistent,
        nextState.persistent,
      );
      if (forwardJsonPatchOperations.length) {
        jsonPatchOperations = {
          forward: handleStatePathsThatNeedToBeUpdatedAsAWhole({
            lastState,
            nextState,
            operations: forwardJsonPatchOperations,
          }),
          reverse: safeJsonPatchCompareOfState(nextState.persistent, lastState.persistent),
        };
      }
    }

    let userEventToAddToState: UserEvent | undefined;

    // This function is also used as part of RxDB update flows
    const { userEvent } = await handleStateUpdateSideEffects({
      ...options,
      addUserEventToZustandState: async (userEvent) => {
        userEventToAddToState = userEvent;
      },
      didChangeAnyState: willChangeAnyState,
      jsonPatchOperations,
    });

    if (userEventToAddToState) {
      addUserEvent(userEventToAddToState);
    }

    // Has `handleStateUpdateSideEffects` decided we don't need to go any further?
    if (!userEvent && !willChangeAnyState) {
      return { userEvent };
    }

    // Update the cached client state if needed
    if (willChangeAnyState && nextState.clientStateLoaded) {
      onClientDataChanged(lastState, nextState);
    }
    return { userEvent };
  };

  return {
    isStateMinimized: Boolean(shouldMinimize),
    update,
    ...getDefaultFullZustandState(),
  };
};

export const globalState = create<
  FullZustandState,
  SetState<FullZustandState>,
  GetState<FullZustandState>,
  StoreApiWithSubscribeWithSelector<FullZustandState>
>(
  subscribeWithSelector(
    addDevToolsMiddleware((...args) => getInitialState({ shouldMinimize: isExtension }, ...args)),
  ),
);

const onClientDataChanged = async (lastState: FullZustandState, nextState: FullZustandState) => {
  if (isEqual(lastState.client, nextState.client)) {
    return;
  }
  await background.setCacheItem('clientData', nextState.client);
};

export const recentUserEventsWithDataUpdates: UserEventWithDataUpdate[] = [];

export const getRecentUserEventsWithDataUpdates = (): typeof recentUserEventsWithDataUpdates =>
  recentUserEventsWithDataUpdates;

export const recentUndoneUserEvents: UserEventWithDataUpdate[] = [];

export const updateState = globalState.getState().update;

export type UpdateStateType = typeof updateState;

/**
 * Updates a property in the global Zustand state using the provided value and options.
 * Cancels the state update if the property value is already equal to the provided value.
 * Ensures this state update cannot cause a 'state unchanged' warning.
 */
export function updatePropertyInState<PropertyName extends keyof FullZustandState>(
  propertyName: PropertyName,
  value: FullZustandState[PropertyName],
  options: Parameters<typeof updateState>[1],
): ReturnType<typeof updateState> {
  return updateState((state) => {
    if (isEqual(state[propertyName], value)) {
      throw new CancelStateUpdate();
    }
    state[propertyName] = value;
  }, options);
}

/**
 * Same as above, but for state.client.
 */
export function updatePropertyInClientState<PropertyName extends keyof ClientState>(
  propertyName: PropertyName,
  value: ClientState[PropertyName],
  options: Parameters<typeof updateState>[1],
): ReturnType<typeof updateState> {
  return updateState((state) => {
    if (isEqual(state.client[propertyName], value)) {
      throw new CancelStateUpdate();
    }
    state.client[propertyName] = value;
  }, options);
}

export const updateFocusedHighlightIdState = focusedHighlightIdState.getState().update;

export const isStaffProfile = (state: FullZustandState) => {
  const profile = state.client.profile;

  if (!profile) {
    return false;
  }

  return profile.is_staff;
};
// Don't use this to hide anything important. A user could potentially set their account to staff in the front-end.
export const useIsStaffProfile = (): boolean => {
  return globalState(useCallback((state) => isStaffProfile(state), []));
};

export const useDisablePersistentQueryCache = (): boolean => {
  const profile = globalState(useCallback((state) => state.client.profile, []));

  if (!profile || profile.disable_persistent_query_cache === undefined) {
    return true;
  }

  return profile.disable_persistent_query_cache;
};
